var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-view',{ref:"rv"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExactActive),expression:"isExactActive"}]},[_c('div',{staticClass:"navbarCustom",staticStyle:{"margin-bottom":"60px"}},[_c('div',{staticClass:"logo-left",staticStyle:{"margin-left":"5px"}},[_c('img',{staticClass:"logo_ic",attrs:{"src":require("@/assets/icons/back_ic.svg")},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('span',{staticClass:"nav_title"},[_vm._v(_vm._s(this.$route.query.on))])])]),_c('b-overlay',{attrs:{"show":_vm.loading,"variant":"transparent","no-wrap":""}}),(_vm.loaded)?_c('div',{staticClass:"songs",staticStyle:{"margin-top":"60px","margin-bottom":"55px"}},_vm._l((_vm.songs),function(songs){return _c('div',{key:songs.id,staticClass:"song"},[_c('router-link',{attrs:{"to":{
            name: 'PlayList',
            params: {
              id: songs.id,
              slug: _vm.song_detail(songs.mp3_title),
              singer: songs.mp3_artist,
              title: songs.mp3_title,
            },
            query: { singer: _vm.getSinger(songs.mp3_artist, songs.category_name)},
          }}},[_c('progressive-img',{staticClass:"song_img_l",attrs:{"blur":0,"placeholder":"https://alemsesi.com/api/placeholder.webp","fallback":"https://alemsesi.com/api/placeholder.webp","src":songs.mp3_thumbnail_s}}),_c('div',{staticClass:"song_data_l"},[_c('div',{staticClass:"s_title"},[_vm._v(_vm._s(songs.mp3_title))]),(songs.mp3_artist == 'Beýlekiler (Other)')?_c('div',[_c('div',{staticClass:"s_artist"},[_vm._v(_vm._s(songs.category_name))])]):_c('div',[_c('div',{staticClass:"s_artist"},[_vm._v(_vm._s(songs.mp3_artist))])])])],1)],1)}),0):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }